.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.h-screen-80 {
  height: 80vh;
}

.h-screen-90 {
  height: 90vh;
}

.l-80 {
  left: 80%;
}

@font-face {
  font-family: 'BebasNeue';
  display: swap;
  src: url('./assets/Fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bau';
  display: swap;
  src: url('./assets/Fonts/Bau-OT-normal-400-100.otf') format('truetype');
}

@font-face {
  font-family: 'Bau-700';
  display: swap;
  src: url('./assets/Fonts/tesla/TESLA.ttf') format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-font {
  font-family: "BebasNeue";
}

.secondery-font-400 {
  font-family: "Bau";
}

.secondery-font-700 {
  font-family: "Bau-700";
}

.black-background {
  background: rgba(15, 15, 15, 0.30);
}

.black-border {
  border: 1px solid var(--Black-15, #262626);
}

.light-border {
  border: 0.82px solid #474646;
}

.gray-border {
  border: 2.416px solid rgba(255, 255, 255, 0.20);
}

.black-backdrop {
  backdrop-filter: blur(12px);
}

.dark-backround {
  background: rgba(0, 0, 0, 0.09);
}

.nav-bg {
  background: rgba(11, 11, 11, 0.60);
  backdrop-filter: blur(10.5px);
}

.backdrop-18 {
  backdrop-filter: blur(18px);
}

.color-gray {
  color: #ABABAB;
}

.background-gray {
  background-image: url('./assets/Images/award-bg.JPEG');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.width-70 {
  width: 70.666%
}

.w-105 {
  width: 105%;
}

.h-120 {
  height: 120%;
}

.button-border {
  border-top-width: 0.5px;
  border-left-width: 0.5px;
}

.backgroud-image-gray {
  background-image: url('./assets/Images/background-gray.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroud-image-contact {
  background-image: url('./assets/Images/contact-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backgroud-image-hero {
  background-image: url('./assets/Images/text-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: contain; */
  /* width: 40%; */
  /* height: 50%; */
}

.slider {
  height: 185px;
  overflow: hidden;
}

.slide-track {
  display: flex;
  animation: scroll 12s linear infinite;
}

.slide {
  padding: 0 50px;
  color: transparent;
  -webkit-text-stroke-width: 1.3px;
  -webkit-text-stroke-color: white;
  font-size: 140px;
  white-space: nowrap;
  width: 100%;
  height: 179px;
  font-weight: 700;
  line-height: 150px;
  letter-spacing: 0.08em;
}

@keyframes scroll-small {
  0% {
    transform: translateX(95%);
  }

  100% {
    transform: translateX(-620%);
  }
}

@keyframes scroll-large {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-300%);
  }
}

.slide-track {
  display: flex;
  animation: scroll-small 10s linear infinite;
}

@media (min-width: 660px) {
  .slide-track {
    animation-name: scroll-large;
  }
}

.height-large {
  height: 525px;
}

/* This will apply to all scrollable elements */
::-webkit-scrollbar {
  width: 5px;
  /* Adjust scrollbar width */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* Make the track transparent */
}

::-webkit-scrollbar-thumb {
  background: rgba(32, 32, 32, 0.3);
  ;
  /* Make the handle black */
  /* border: .2px solid var(--Black-15, #262626); */
  border-radius: 10px;
  /* Optional: Add border radius to the handle */
}

video {
  object-fit: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}